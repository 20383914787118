
    import {computed, defineComponent, onMounted, reactive, toRefs} from 'vue';
    import apiTalent from "@/request/apis/api_talent";
    import PageUtil from "@/utils/page_util";
    import {message} from "ant-design-vue/es";
    import {IArticleItem} from "@/models/article_model";
    import ApiArticle from "@/request/apis/api_article";

    interface IState {
        list: any,
        visible: any,
        title: any,
        name: any,
        total: any,
        searches: any,
        editId: any,
        type: any,
        pid:any,
        sonData:any,
        visible2:any,
        visible3:any,
        position:any,
        title2:any,
        metnodsType:any,
        form:any,
        list2:[]
    }


    export default defineComponent({
        name: 'salaryRange',
        components: {},
        setup() {
            let state: IState = reactive({
                visible: false,
                title: '',
                metnodsType:'',
                name: '',
                list: [],
                list2:[],
                total: 0,
                searches: {
                    name: '',
                    level:3,
                    page:PageUtil.pageNo,
                    limit:PageUtil.pageSize,
                },
                editId: '',
                type: '',
                pid:'',
                sonData:[],
                visible3:false,
                position:'',
                visible2:false,
                title2:'',
                form:{
                    first:undefined,
                    second:undefined,
                    name:'',
                    sn:'',
                    ids:''
                }
            })
            const columns = [
                {
                    title: 'id',
                    dataIndex: 'id',
                    key: 'id',
                },
                {
                    title: '编码',
                    dataIndex: 'sn',
                    key: 'sn',
                },
                {
                    title: '职位名称',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: '一级分类',
                    dataIndex: 'grandpa',
                    key: 'grandpa',
                },
                {
                    title: '二级分类',
                    dataIndex: 'father',
                    key: 'father',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                },
                {
                    title: '添加时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                },
                {
                    title: '最后修改时间',
                    dataIndex: 'update_time',
                    key: 'update_time',
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    key: 'action',
                    width:150
                },
            ]

            const handleTableChange = (pages: any) => {
                state.searches.limit = pages.pageSize
                state.searches.page = pages.current
                getList()
            };

            const getList = async () => {
                const params = {...state.searches}
                const res = await apiTalent.positionList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list = data
                }
            }

            const getList2 = async () => {
                const params = {name:""}
                const res = await apiTalent.positionList(params)
                if (res && res.error_code == 0) {
                    const data: any = res.data
                    state.list2 = data
                }
            }


            const handleOk2 = async() => {

                if(state.metnodsType == 1){
                    const ids = state.form.first + ','+ state.form.second
                    const params = {name: state.form.name, pid:state.form.second,level:3,sn:state.form.sn,ids:ids}
                    const res = await apiTalent.positionAdd(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible3= false
                        await getList()
                    }else{
                        message.error(res.msg)
                    }
                }else{
                    const ids = state.form.first + ','+ state.form.second
                    const params = {name: state.form.name, pid:state.form.second,level: 3,id:state.editId,sn:state.form.sn,ids:ids}
                    const res = await apiTalent.positionEdit(params)
                    if (res && res.error_code == 0) {
                        message.success(res.msg)
                        state.visible3= false
                        await getList()
                    }else{
                        message.error(res.msg)
                    }
                }

            }
            const onDel = async (val,val2) => {
                const params = {id: val.id,status:val2,pid:state.pid}
                const res = await apiTalent.positionStatus(params)
                if (res) {
                    message.destroy()
                    if (res.error_code === 0) {
                        message.success(res.msg)
                        await getList()
                        state.visible = false
                        state.visible2 = false
                        return
                    }
                    message.error(res.msg)
                }
            }

            const onSearch = () => {
                    state.searches.level=3,
                    state.searches.page=1,
                    state.searches.limit=PageUtil.pageSize,
                getList()
            }

            const onReset = () => {
                state.searches = {
                    page:1,
                    limit:PageUtil.pageSize,
                    name:"",
                    level:3
                }
                getList()
            }

            const seeSon = async(val) => {
                state.pid = val.id
                const  params = {pid:val.id}
                const res = await apiTalent.positionChildrenList(params)
                if (res) {
                    if (res.error_code == 0) {
                        const data:any = res.data
                        state.sonData = data
                        state.visible2 = true
                    }else{
                        message.error(res.msg)
                    }

                }
            }

            const addPosition = () => {
                state.visible3 = true
                state.title = "新增职位"
                state.metnodsType = 1
                state.position =""
                state.form={
                    fisrt:undefined,
                    second:undefined,
                    name:'',
                    sn:'',
                    ids:''
                }
            }

            const getSecond = async(val) => {
                state.type = Number(val)
                // state.pid = state.form.first
                state.sonData = []
                if(state.type == 1){
                    state.form.second = state.form.second
                }else{
                    state.form.second = undefined
                }
                const  params = {pid:state.form.first}
                const res = await apiTalent.positionChildrenList(params)
                if (res) {
                    if (res.error_code == 0) {
                        const data:any = res.data
                        state.sonData = data
                    }else{
                        message.error(res.msg)
                    }
                }
            }

            const edit = (val) => {
                state.title = "编辑职位信息"
                state.form.name = val.name
                state.form.sn = val.sn
                state.form.first =  Number(val.father.grandpa.id)
                state.form.second = Number(val.father.id)
                state.editId = val.id
                state.metnodsType = 2
                getSecond(1)
                state.visible3 = true
            }

            const pagination = computed(() => ({
                total: state.total,
                current: state.searches.page,
                pageSize: state.searches.limit,
                showTotal: (total: any) => `共${total}条数据`,
                defaultPageSize: 10,
                // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
                showSizeChanger: true, // 是否显示pageSize选择
                showQuickJumper: true, // 是否显示跳转窗
            }));
            onMounted(() => {
                getList()
                getList2()
            })

            return {
                ...toRefs(state),
                columns,
                getSecond,
                edit,
                getList2,
                seeSon,
                pagination,
                onSearch,
                onReset,
                handleOk2,
                onDel,
                addPosition,
                handleTableChange
            }
        }
    });
